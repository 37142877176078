import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import ModalContact from 'aulatime/src/components/ModalContact';

const SectionBusiness = () => {

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          station {
            metadata {
              domain
              shortcode
            }
          }
        }
      }
      img: file(relativePath: { eq: "img_business.png" }) {
        childImageSharp {
          fixed(width: 400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const postmanpConfig = {
    metadata: {
      ...data.site.siteMetadata.station.metadata,
      ...{ lang: process.env.GATSBY_LOCALE || 'en' },
      ...{ form: 'contact', section: '#Business' }
    }
  };

  return (
    <section id="business">
      <div className="container">
        <div className="row">

          <div className="col text-center">
            <h1 className="mb-0 pb-0">Business Spanish</h1>
            <h2 className="text-primary pb-4">for Professionals</h2>
          </div>

        </div>
      </div>

      <div className="container">
        <div className="row">

          <div className="col-lg-6 col-md-7 col-12 text-md-right text-center">

            <p className="text-primary lead"><strong>Boost Your Career with Spanish</strong></p>
            <p className="lead">Need Spanish to advance in your career? We offer courses tailored specifically for the business world.</p>
            <p>Our <span className="text-primary"><strong>tutors</strong></span> will design a <span className="text-primary"><strong>customized learning path</strong></span> that focuses on what you need for your specific industry, helping you speak confidently and professionally in any business setting.</p>
            <p>Unlock new opportunities with business-focused Spanish!</p>

            <div className="pt-3">
              <ModalContact
                header="Business, por favor"
                button={(onClick) => (
                  <button type="button" className="btn btn-xl btn-dark" onClick={onClick}>Business, por favor</button>
                )}
                postmanpConfig={postmanpConfig}
              />
            </div>

          </div>

          <div className="col-lg-6 col-md-5 col-12 d-flex">
            <Img
              fixed={data.img.childImageSharp.fixed}
              className="mb-0 mt-5 mt-md-2 mx-auto"
              alt="Business"
            />
          </div>

        </div>
      </div>
    </section>
  );
}

export default SectionBusiness;
