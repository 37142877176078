import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import ModalContact from 'aulatime/src/components/ModalContact';
import SectionCursos from 'aulatime/src/components/SectionCursos';

const CoursesTable1 = () => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            station {
              metadata {
                domain
                shortcode
              }
            }
          }
        }
      }
    `
  );

  const postmanpConfig = {
    metadata: {
      ...data.site.siteMetadata.station.metadata,
      ...{ lang: process.env.GATSBY_LOCALE || 'en' },
      ...{ form: 'book', section: '#Courses' }
    }
  };

  const button = (onClick) => (
    <button type="button" className="btn btn-lg btn-primary" onClick={onClick}>Book Now!</button>
  );

  const col1 = <div className="col">
    <div className="card card-01">

      <div className="card-header"></div>
      <div className="card-body">

        <div className="hour-comparison text-center">
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <h4>20 hours</h4>
              <h6>of private tuition online</h6>
            </div>
          </div>
        </div>

        <div className="price text-center pt-3 pb-3">
          <h5>€299,<span>99</span></h5>
          <p className="small">VAT incl.</p>
        </div>

        <div>
          <ModalContact
            button={button}
            postmanpConfig={postmanpConfig}
            plan="#20Hours"
          />
        </div>

      </div>
      <div className="card-footer"></div>

    </div>
  </div>

  const col2 = <div className="col">
    <div className="card card-02">

      <div className="card-header"></div>
      <div className="card-body">

        <div className="hour-comparison text-center">
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <h4>30 hours</h4>
              <h6>of private tuition online</h6>
            </div>
          </div>
        </div>

        <div className="price text-center pt-3 pb-3">
          <h5>€349,<span>99</span></h5>
          <p className="small">VAT incl.</p>
        </div>

        <div>
          <ModalContact
            button={button}
            postmanpConfig={postmanpConfig}
            plan="#30Hours"
          />
        </div>

      </div>
      <div className="card-footer"></div>

    </div>
  </div>

  const col3 = <div className="col">
    <div className="card card-03">

      <div className="card-header"></div>
      <div className="card-body">

        <div className="hour-comparison text-center">
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <h4>40 hours</h4>
              <h6>of private tuition online</h6>
            </div>
          </div>
        </div>

        <div className="price text-center pt-3 pb-3">
          <h5>€399,<span>99</span></h5>
          <p className="small">VAT incl.</p>
        </div>

        <div>
          <ModalContact
            button={button}
            postmanpConfig={postmanpConfig}
            plan="#40Hours"
          />
        </div>

      </div>
      <div className="card-footer"></div>

    </div>
  </div>

  const header = <div className="container">
    <div className="row">
      <div className="col text-center pb-2">
        <h1 className="mb-3">Courses</h1>
        <p className="lead mb-1"><span className="text-primary font-weight-bold">Private Tuition</span> - One-to-one attention for focused learning.</p>
        <p className="lead mb-1"><span className="text-primary font-weight-bold">Small Groups</span> - Intimate classes (1 to 3 students) for a collaborative experience.</p>
        <p className="lead"><span className="text-primary font-weight-bold">Personalized Programs</span> - Engaging, practical lessons tailored to your needs.</p>
        <p><strong>Spanish for ESO and Bachillerato</strong> - Suitable for all levels, from beginner to advanced.</p>
        <p>Start learning Spanish the way it's meant to be - effective, enjoyable, and tailored just for you!</p>
      </div>
    </div>

    <div className="row">
      <div className="col-lg-10 offset-lg-1 col-12">

        <h2 className="mb-3 text-center">Why Choose Us?</h2>
        <ul className="list-group mb-2">
          <li className="list-group-item"><span className="font-weight-bold">Live, One-on-One Lessons</span> - No automation, just personalized learning with your tutor.</li>
          <li className="list-group-item"><span className="font-weight-bold">Expand Your Vocabulary</span> - Learn new words and phrases naturally.</li>
          <li className="list-group-item"><span className="font-weight-bold">Perfect Your Accent</span> - Speak like a native with expert guidance.</li>
          <li className="list-group-item"><span className="font-weight-bold">Authentic Expressions</span> - Say things the way locals do.</li>
          <li className="list-group-item"><span className="font-weight-bold">Flexible Hours</span> - Learn on your schedule, with 100% recoverable lessons.</li>
        </ul>

      </div>
    </div>
  </div>

  return (
    <SectionCursos
      id="cursos-tabla-1"
      header={header}
      cols={[col1, col2, col3]}
    />
  );

}

export default CoursesTable1;
