import React from 'react';
import BackgroundImage from 'gatsby-background-image';

import DividerLeft from './DividerLeft';
import DividerRight from './DividerRight';

const ChunkScenic = (props) => {

  return (
    <div id="scenic">
      <DividerRight/>
        <BackgroundImage
          Tag="div"
          className="bg"
          fluid={props.fluid}
          backgroundColor={props.primaryColor}
        />
      <DividerLeft/>
    </div>
  );
}

export default ChunkScenic;
