import React, { useState, useEffect, useRef } from 'react';

import Loading from './Loading';
import GoogleMapPaint from './GoogleMapPaint';

const GoogleMap = ({ config }) => {

  const [loaded, setLoaded] = useState(false);
  const [fallback, setFallback] = useState(false);
  const loadingRef = useRef();

  useEffect(
    () => {

      const lang = process.env.GATSBY_LOCALE || 'es';

      const load = () => {
        return new Promise((resolve, reject) => {

          if (typeof window.google === 'undefined') {

            const existingScript = document.getElementById(config.id);
            if (existingScript) {
              existingScript.parentNode.removeChild(existingScript);
            }

            const script = document.createElement('script');

            script.id = config.id;
            script.async = true;

            document.body.append(script);

            script.onload = () => {
              resolve('Loaded');
            };
            script.onerror = () => {
              if (script.parentNode) script.parentNode.removeChild(script);
              reject('Load failed');
            };

            script.src = `//maps.googleapis.com/maps/api/js?key=${config.apikey}&map_ids=${config.mapid}&language=${lang}&region=ES&signature=${config.signature}`;

          } else {
            resolve('Repaint');
          }

        });
      }

      const retry = (callback, retries = -1, interval = 2000) => {
        return new Promise((resolve, reject) => {

          callback()
          .then((message) => {
            resolve(message);
          })
          .catch((error) => {
            console.error(error);

            setFallback(true);
            if (error !== 'Exists') {
              setTimeout(() => {
                if (retries === 1) {
                  //reject('Maximum retries exceeded');
                  return;
                }

                retry(callback, (retries !== -1 ) ? retries - 1 : -1, interval);
              }, interval);
            }
          });

        });
      };

      retry(load, config.retries, config.interval)
      .then((message) => {
        console.log(message);
        setLoaded(true);
        setFallback(false);

        //
        console.log('Google Map Painting');
        GoogleMapPaint({
          config: config,
          callback: () => {
            loadingRef.current.remove();
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });

    }, [config]
  );

  return (
    <>
    {loaded && (
      <>
        <div ref={loadingRef}><Loading/></div>
        <div id="mapa"></div>
      </>
    )}
    {fallback && (
      <div id="mapaStatic"></div>
    )}
    </>
  );
}

export default GoogleMap;
