import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Link } from 'react-scroll';

import SectionDigital from 'aulatime/src/components/SectionDigital';

const ChunkDigital = () => {

  const data = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "img_online.png" }) {
        childImageSharp {
          fixed(width: 400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <SectionDigital>
      <div className="container">
        <div className="row">

          <div className="col text-center">
            <h1 className="text-white mb-0 pb-0">Online Courses</h1>
            <h2 className="text-primary-2 pb-4">and Certification</h2>
          </div>

        </div>
      </div>
      <div className="container">
        <div className="row">

          <div className="col-lg-6 col-md-5 col-12 d-flex">
            <Img
              fixed={data.img.childImageSharp.fixed}
              className="mb-md-0 mb-4 mt-md-2 mx-auto"
              alt="Aula Spanish Time"
            />
          </div>
          <div className="col-lg-6 col-md-7 col-12 text-md-left text-center">
            <p className="lead text-white">At <span className="text-primary-2"><strong>Aula Spanish Time</strong></span>, we provide a fully online service, so you can take your classes <span className="text-primary-2"><strong>100% from home</strong></span>.</p>
            <p className="text-white">With the latest technology in place, you’ll experience the feel of <span className="text-primary-2"><strong>private tuition</strong></span> from the comfort of your own space - just like being in one of our classrooms.</p>
            <p className="lead text-white pb-3">Learn Spanish, anytime, anywhere!</p>

            <Link href="#" className="btn btn-xl btn-light" to="contacto" smooth={"easeInOutCubic"} duration={1500} offset={-103}>Get In Touch</Link>
          </div>
        </div>
      </div>
    </SectionDigital>
  );
}

export default ChunkDigital;
