import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import ModalContact from 'aulatime/src/components/ModalContact';
import SectionExamenes from 'aulatime/src/components/SectionExamenes';

import LogoDele from '../img/logo_dele.png';
import LogoCI from '../img/logo_instituto-cervantes.png';

const ChunkExams = () => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            station {
              metadata {
                domain
                shortcode
              }
            }
          }
        }
      }
    `
  );

  const postmanpConfig = {
    metadata: {
      ...data.site.siteMetadata.station.metadata,
      ...{ lang: process.env.GATSBY_LOCALE || 'en' },
      ...{ form: 'contact', section: '#Exams' }
    }
  };

  return (
    <SectionExamenes>
      <div className="container">
        <div className="row">

          <div className="col text-center">
            <h1 className="mb-0 pb-0">Exams</h1>
            <h2 className="text-primary pb-3">and Certification</h2>
          </div>

        </div>
      </div>
      <div className="container">
        <div className="row">

          <div className="col-md-8 col-12 text-md-right text-center">
            <p className="lead">We take certification seriously, and our goal is to help you <span className="text-primary"><strong>ace that exam</strong></span> - no unnecessary time in the classroom!</p>
            <p className="lead">Our <span className="text-primary"><strong>expert tutors</strong></span> will:</p>

            <ul className="list-group list-group-flush">
              <li className="list-group-item">Assess your current Spanish level (A1 - C2).</li>
              <li className="list-group-item">Tailor a course plan to your specific needs.</li>
              <li className="list-group-item">Guide you through every step to ensure you <span className="text-primary"><strong>pass with confidence</strong></span>.</li>
            </ul>

            <p className="lead mt-3">Achieve your certification from <span className="text-primary"><strong>A1 to C2</strong></span> quickly and effectively!</p>

            <div className="pt-3">
              <ModalContact
                button={(onClick) => (
                  <button type="button" className="btn btn-xl btn-primary-2" onClick={onClick}>Book Now!</button>
                )}
                postmanpConfig={postmanpConfig}
              />
            </div>
          </div>

          <div className="col-md-4 col-12 text-md-left text-center">

              <div className="col">
                <a href="https://examenes.cervantes.es/" target="_blank" rel="noopener noreferrer" aria-label="DELE"><img src={LogoDele} alt="DELE" className="img-fluid mt-md-2 mt-5 pl-md-2" /></a>
              </div>
              <div className="col">
                <a href="https://examenes.cervantes.es/" target="_blank" rel="noopener noreferrer" aria-label="Instituto Cervantes"><img src={LogoCI} alt="Instituto Cervantes" className="img-fluid mt-3 pl-md-2" /></a>
              </div>

          </div>
        </div>
      </div>
    </SectionExamenes>
  );
}

export default ChunkExams;
