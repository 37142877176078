import React, { useState } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

import Header from '../components/Header';
import Footer from '../components/Footer';

import ChunkWelcome from '../components/ChunkWelcome';
import ChunkCourses from '../components/ChunkCourses';

import SectionBrushUp from '../components/SectionBrushUp';
import SectionBusiness from '../components/SectionBusiness';

import ChunkDigital from '../components/ChunkDigital';
import ChunkExams from '../components/ChunkExams';
import ChunkTestimonies from '../components/ChunkTestimonies';
import SectionContacto from 'aulatime/src/components/SectionContacto';
import ChunkScenic from 'aulatime/src/components/ChunkScenic';

import PopupRebajas from 'aulatime/src/components/PopupRebajas';

const IndexPage = ({ data }) => {

  const googleMapsConfig = data.site.siteMetadata.googleMaps;

  const postmanpConfig = {
    metadata: {
      ...data.site.siteMetadata.station.metadata,
      ...{ lang: process.env.GATSBY_LOCALE || 'en' },
      ...{ form: 'contact', section: '#ContactUs' }
    }
  };

  const privacyState = useState(false);
  const popupBody = <>
    <div>
      <h4>Special</h4>
      <h5>50 hours</h5>
    </div>
    <div className="price text-center py-md-1 py-2">
      <h5>€499,<span>99</span></h5>
      <p className="mb-md-1 mb-2">VAT incl.</p>
    </div>
    <a href="/special-classes-online" className="btn btn-sm btn-dark">Tell Me More</a>
  </>

  return (
    <Layout privacyState={privacyState}>
      <SEO title="Spanish Academy, private one-to-one online classes"/>
      <main id="index">
        <Header/>
        <ChunkWelcome/>
        <ChunkCourses/>
        <SectionBrushUp/>
        <SectionBusiness/>
        <ChunkDigital/>
        <ChunkExams/>
        <ChunkTestimonies/>
        <SectionContacto
          googleMapsConfig={googleMapsConfig}
          postmanpConfig={postmanpConfig}
          email={data.site.siteMetadata.station.email}
          chunkScenic=<ChunkScenic primaryColor="#cc0000" fluid={data.banner_scenic.childImageSharp.fluid} />
        />
        <Footer/>
      </main>
      <PopupRebajas body={popupBody} privacyState={privacyState} />
    </Layout>
  );
}

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        station {
          email
          metadata {
            domain
            shortcode
          }
        }
        googleMaps {
          apikey
          mapid
          coords {
            lat
            lng
          }
          markerColor
        }
      }
    }
    banner_scenic: file(relativePath: { eq: "banner_scenic.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 2625) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
