import React from 'react';
import { Link } from 'react-scroll';
import { t, c, jt } from 'ttag';

import ContactoDatos from './ContactoDatos';
import ContactoMapa from './ContactoMapa';

import ContactForm from 'contactfo';

import WhatsAppChat from '../components/WhatsAppChat';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopHouse } from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faLaptopHouse);

function textStrong(text) {
  return <strong>{text}</strong>
}

const SectionContacto = (props) => {

  const frag1 = textStrong(t`teléfono`)
  const frag2 = textStrong(t`WhatsApp`)
  const frag3 = textStrong(t`email`)
  const linkToForm = <Link href="#" to="contactoForm" smooth={"easeInOutCubic"} duration={2500} offset={-103} key="linkToForm">
    {textStrong(t`formulario`)}
  </Link>
  const frag5 = textStrong(c('messageUs').t`Facebook`)
  const frag6 = textStrong(t`botón de chat`)
  const p1 = <p>{jt`Te atendemos mediante ${frag1}, ${frag2}, ${frag3}, ${linkToForm} o ${frag5}, incluso también pinchando al ${frag6} a la derecha y hablando a nuestro agente para ser atendido de inmediato`}.</p>

  var scenic = (props) => {
    if (props.chunkScenic) {
      return props.chunkScenic
    }
    return <ContactoMapa googleMapsConfig={props.googleMapsConfig} />
  }

  return (
    <section id="contacto">

      <div id="contactoInfo">
        <div className="container">

          <div className="col-lg-10 col-12 mx-auto text-center">
            <h1 className="mb-4">{jt`Contáctanos`}</h1>
            {p1}
            <p className="lead">{jt`¡Estaríamos encantados de atender tu consulta para ayudarte a sacar el título en tiempo récord!`}</p>
          </div>

        </div>
      </div>

      <div id="contactoBotones">
        <div className="container">

          <div className="row justify-content-center">
            <div className="col-auto">
              <WhatsAppChat/>
            </div>
            <div className="col-auto">
              <div id="gotoForm">
                <Link href="#" to="contactoForm" smooth={"easeInOutCubic"} duration={1500} offset={-103} className="stretched-link">
                  <div className="row align-items-center">
                    <div className="col">{jt`Formulario online`}</div>
                    <div className="col-auto">
                      <FontAwesomeIcon icon="laptop-house" />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>

        </div>
      </div>

      <ContactoDatos email={props.email}/>

      {scenic(props)}

      <div id="contactoForm">
        <div className="container">

          <div className="rounded col-lg-8 offset-lg-2 col-12 px-0">
            <ContactForm
              metadata={props.postmanpConfig.metadata}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionContacto;
