import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import SectionBienvenidos from 'aulatime/src/components/SectionBienvenidos';

import DividerLeft from 'aulatime/src/components/DividerLeft';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboardTeacher, faUserGraduate, faBusinessTime, faComments, faThumbsUp, faGlobeEurope, faUsers, faLanguage, faCaretRight } from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faChalkboardTeacher, faUserGraduate, faBusinessTime, faComments, faThumbsUp, faGlobeEurope, faUsers, faLanguage, faCaretRight);

const ChunkBienvenidos = () => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            freshchat {
              token
              host
              locale
              siteId
              tags
              config {
                headerProperty {
                  appName
                  appLogo
                  backgroundColor
                }
              }
            }
          }
        }
      }
    `
  );
  const fcConfig = data.site.siteMetadata.freshchat;

  return (
    <SectionBienvenidos fcConfig={fcConfig}>
      <div className="container">
        <div className="row">

          <div className="col-lg-10 offset-lg-1 col-12 text-center">
            <h1 className="mb-4">Welcome</h1>
            <h2 className="text-primary">Moving to Spain?</h2>
            <h3 className="text-primary">A Digital Nomad Looking to Master Spanish?</h3>
            <p className="lead">Join our <strong>Results-Driven, Conversation-Focused</strong> Online Spanish Classes and start speaking with confidence!. We'll help you adapt quickly and immerse yourself in Spanish culture with ease.</p>
            <p>Our passion for teaching drives us to deliver <strong>consistent value, real progress, and fast, reliable results</strong>.</p>
          </div>

        </div>
        <div className="row row-cols-xl-4 row-cols-md-2 row-cols-1 proceso pt-3">
          <div className="col">

            <div className="card">
              <div className="card-body">
                <p><strong>Live, interactive lessons</strong> with expert tutors</p>
              </div>

              <div className="card-footer">
                <DividerLeft/>
              </div>
            </div>

          </div>
          <div className="col">

            <div className="card">
              <div className="card-body">
                <p><strong>Flexible scheduling</strong> to fit your lifestyle.</p>
              </div>
              <div className="card-footer">
                <DividerLeft/>
              </div>
            </div>

          </div>
          <div className="col">

            <div className="card">
              <div className="card-body">
              <p><strong>Clear explanations in English</strong> for easy learning.</p>
              </div>
              <div className="card-footer">
                <DividerLeft/>
              </div>
            </div>

          </div>
          <div className="col">

            <div className="card">
              <div className="card-body">
                <p><strong>DELE Exam preparation</strong> - all levels - A1, A2, B1, B2, C1, C2</p>
              </div>
              <div className="card-footer">
                <DividerLeft/>
              </div>
            </div>

          </div>
        </div>

        <div className="row destacados">
          <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-12">

            <div className="text-center">
              <h3 className="pt-4 mb-3">Practice Over Theory</h3>
              <p className="lead">You can memorize textbooks and ace online quizzes, but true learning happens through real practice. That’s why <strong>private online Spanish classes</strong> are the best way to master the language.</p>
              <p>Make the switch to private lessons and experience the difference!</p>
            </div>

            <div className="row row-cols-1">

              <div className="col mb-3">
                <div className="card">
                  <div className="card-body">

                    <div className="row align-items-center">
                      <div className="col-md-2 text-center text-primary-red py-2">
                        <FontAwesomeIcon icon="chalkboard-teacher" size="2x" />
                      </div>
                      <div className="col-md text-md-left text-center py-2">
                        <h5><strong>Native Teachers</strong></h5>
                        <p className="mb-0">Master authentic pronunciation and conversation.</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col mb-3">
                <div className="card">
                  <div className="card-body">

                    <div className="row align-items-center">
                      <div className="col-md-2 text-center text-primary-red py-2">
                        <FontAwesomeIcon icon="user-graduate" size="2x" />
                      </div>
                      <div className="col-md text-md-left text-center py-2">
                        <h5><strong>Private One-on-One Coaching</strong></h5>
                        <p className="mb-0">Personalized lessons tailored to you.</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col mb-3">
                <div className="card">
                  <div className="card-body">

                    <div className="row align-items-center">
                      <div className="col-md-2 text-center text-primary-red py-2">
                        <FontAwesomeIcon icon="comments" size="2x" />
                      </div>
                      <div className="col-md text-md-left text-center py-2">
                        <h5><strong>Clear Explanations in English</strong></h5>
                        <p className="mb-0">Understand language concepts with ease.</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col mb-3">
                <div className="card">
                  <div className="card-body">

                    <div className="row align-items-center">
                      <div className="col-md-2 text-center text-primary-red py-2">
                        <FontAwesomeIcon icon="business-time" size="2x" />
                      </div>
                      <div className="col-md text-md-left text-center py-2">
                        <h5><strong>100% Flexible Schedule</strong></h5>
                        <p className="mb-0">Learn at your pace, anytime, anywhere.</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>

            <div className="text-center">
              <p className="lead pt-4">Start speaking Spanish with confidence today!</p>
              <p className="lead"><strong>Limited-time offers available!</strong> Book your course today and start your Spanish journey with savings.</p>
            </div>
          </div>
        </div>

        <div className="row destacados">
          <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-12">

            <h3 className="pt-4 mb-3 text-center">What We Offer</h3>

            <div className="row row-cols-1">

              <div className="col mb-3">
                <div className="card">
                  <div className="card-body">

                    <div className="row align-items-center">
                      <div className="col-md-2 text-center text-primary-red py-2">
                        <FontAwesomeIcon icon="language" size="2x" />
                      </div>
                      <div className="col-md text-md-left text-center py-2">
                        <h5><strong>Perfect Your Accent</strong></h5>
                        <p className="mb-0">Sound more natural and confident.</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col mb-3">
                <div className="card">
                  <div className="card-body">

                    <div className="row align-items-center">
                      <div className="col-md-2 text-center text-primary-red py-2">
                        <FontAwesomeIcon icon="users" size="2x" />
                      </div>
                      <div className="col-md text-md-left text-center py-2">
                        <h5><strong>Speak Like a Native</strong></h5>
                        <p className="mb-0">Learn authentic expressions and phrasing.</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col mb-3">
                <div className="card">
                  <div className="card-body">

                    <div className="row align-items-center">
                      <div className="col-md-2 text-center text-primary-red py-2">
                        <FontAwesomeIcon icon="globe-europe" size="2x" />
                      </div>
                      <div className="col-md text-md-left text-center py-2">
                        <h5><strong>Embrace the Culture</strong></h5>
                        <p className="mb-0">Master the nuances of Spanish communication.</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <p className="lead pt-4 text-center">Elevate your Spanish skills and blend in effortlessly!</p>
          </div>

        </div>
      </div>
    </SectionBienvenidos>
  );
}
export default ChunkBienvenidos;
