import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import ModalContact from 'aulatime/src/components/ModalContact';
import SectionCursos from 'aulatime/src/components/SectionCursos';

const CoursesTable3 = () => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            station {
              metadata {
                domain
                shortcode
              }
            }
          }
        }
      }
    `
  );

  const postmanpConfig = {
    metadata: {
      ...data.site.siteMetadata.station.metadata,
      ...{ lang: process.env.GATSBY_LOCALE || 'en' },
      ...{ form: 'book', section: '#Courses' }
    }
  };

  const button = (onClick) => (
    <button type="button" className="btn btn-lg btn-primary" onClick={onClick}>Book Now!</button>
  );

  const col1 = <div className="col">
    <div className="card card-01">

      <div className="card-header"></div>
      <div className="card-body">

        <div className="hour-comparison text-center">
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <h4>80 hours</h4>
              <h6>of private tuition online</h6>
            </div>
          </div>
        </div>

        <div className="price text-center pt-3 pb-3">
          <h5>€749,<span>99</span></h5>
          <p className="small">VAT incl.</p>
        </div>

        <div>
          <ModalContact
            button={button}
            postmanpConfig={postmanpConfig}
            plan="#80Hours"
          />
        </div>

      </div>
      <div className="card-footer"></div>

    </div>
  </div>

  const col2 = <div className="col">
    <div className="card card-02">

      <div className="card-header"></div>
      <div className="card-body">

        <div className="hour-comparison text-center">
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <h4>90 hours</h4>
              <h6>of private tuition online</h6>
            </div>
          </div>
        </div>

        <div className="price text-center pt-3 pb-3">
          <h5>€849,<span>99</span></h5>
          <p className="small">VAT incl.</p>
        </div>

        <div>
          <ModalContact
            button={button}
            postmanpConfig={postmanpConfig}
            plan="#90Hours"
          />
        </div>

      </div>
      <div className="card-footer"></div>

    </div>
  </div>

  const col3 = <div className="col">
    <div className="card card-03">

      <div className="card-header"></div>
      <div className="card-body">

        <div className="hour-comparison text-center">
          <div className="row justify-content-center">
            <div className="col-auto hours">
              <h4>100 hours</h4>
              <h6>of private tuition online</h6>
            </div>
          </div>
        </div>

        <div className="price text-center pt-3 pb-3">
          <h5>€899,<span>99</span></h5>
          <p className="small">VAT incl.</p>
        </div>

        <div>
          <ModalContact
            button={button}
            postmanpConfig={postmanpConfig}
            plan="#100Hours"
          />
        </div>

      </div>
      <div className="card-footer"></div>

    </div>
  </div>

  return (
    <SectionCursos
      id="cursos-tabla-3"
      cols={[col1, col2, col3]}
    />
  );

}

export default CoursesTable3;
