import React from 'react';

import CoursesTable1 from './CoursesTable1';
import CoursesTable2 from './CoursesTable2';
import CoursesTable3 from './CoursesTable3';

const ChunkCourses = () => (
  <section id="cursos">
    <CoursesTable1/>
    <CoursesTable2/>
    <CoursesTable3/>
  </section>
);

export default ChunkCourses;
